<template>
  <body style="position: relative;">
  <div id="wrap">
		<section>
			<headerLayout></headerLayout>
		</section>
    <main>
        <section class="sub-visual">
            <div class="innWrap">
                <div class="txt">
                    <h2>고객 사례</h2>
                    <!-- <p>국내 최대 용량의 기업전용망을 통한 고품질 인터넷 전용회선 서비스</p> -->
                </div>
                <div class="location">
                    <ul>
                        <li><a>PRODUCTS</a></li>
                        <li><a>Kornet</a></li>
                        <li><span>고객 사례</span></li>
                    </ul>
                </div>
            </div>
        </section>
        <section class="sub-section knCTCas">
            <p class="cas-tit">스마트 캠퍼스 구축을 위한 인프라 고도화/최적화 - 동국대학교</p>
            <div class="innWrap flex">
                <div class="mgr50"><img src="@/assets/images/knCTCas.png" alt="동국대학교 로고" ></div>
                <div>
                    <table class="tbl_type01 tbl_dot">
                        <colgroup>
                            <col style="width: 200px;">
                            <col style="">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th class="agc">업종</th>
                                <td>
                                    <div class="dotList pdt0">
                                        <ul>
                                            <li>교육청(초/중/고 포함)</li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="agc">규모</th>
                                <td>
                                    <div class="dotList pdt0">
                                        <ul>
                                            <li>42,092 명(초/중/고 재학생 수 기준)</li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="agc">제공 서비스</th>
                                <td>
                                    <div class="dotList pdt0">
                                        <ul>
                                            <li>KT 스쿨넷 서비스</li>
                                            <li>KT LTE 전용 서비스</li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="agc">과제</th>
                                <td>
                                    <div class="dotList pdt0">
                                        <ul>
                                            <li>안정적 대학 교육환경 제공</li>
                                            <li>정보보안경영시스템 인증(ISMS)을 위한 완벽한 유·무선 물리적 망분리</li>
                                            <li>무선망 인프라 고도화</li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="agc">도입효과</th>
                                <td>
                                    <div class="dotList pdt0">
                                        <ul>
                                            <li>타사 대비 국내 백본용량 2배, 해외 백본 3배 용량, 해외 PoP 2배 운영으로 고속/고품질 보장 </li>
                                            <li>강력한 암호화 기능으로 인증기준 충족</li>
                                            <li>다양한 무선랜 인증서비스 제공</li>
                                            <li>기존 무선랜 대비 속도가 4배 향상</li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </main>
    <section>
      <footerLayout></footerLayout>
    </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'

export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data () {
    return {
    }
  },
  created: function () {

  },
  mounted: function () {
  },
  methods: {
    clickTp: function () {
    }
  }
}
</script>